@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");

/* Colours */

$black: #00171f;
$dark-blue: #005178;
$blue: #007ea7;
$light-blue: #53acd9;
$white: #fafafa;

/* General Content */

#background {
  background-color: $white;
}

.title {
  color: $black;
  font-family: Poppins, sans-serif;
  font-size: 60px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.body-text {
  color: $black;
  font-family: "Space Mono", monospace;
  font-size: 18px;
  margin-bottom: 0;
  padding: 0 20px;
}

.site-link {
  color: $blue !important;
  font-family: Poppins, sans-serif;
  font-size: 18px;

  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline wavy $light-blue;
  }
}

@media (min-width: 576px) {
  .card-row {
    margin-bottom: 20px;
  }
}

.card {
  background-color: $black !important;
  margin: 0 20px;
}

.card-title {
  color: $white;
  font-family: "Space Mono", monospace;
  text-align: center;
  margin: 16px 16px 0;
}

.card-body {
  color: $white;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.card-buttons {
  display: flex;
  justify-content: center;
}

.card-button {
  color: $black;
  background-color: $white;
  border-color: $white;
  font-family: "Space Mono", monospace;
  margin-bottom: 22px;
  margin-right: 10px;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}

.card-button-last {
  margin-right: 0;
}

/* Socials */

.socials {
  display: flex;
  margin-bottom: 10px;
  padding: 0 20px;
}

.socials-icon {
  color: $blue;
  width: 24px;
}

.link-wrapper-icon {
  margin-right: 24px;
}

/* Main */

#main {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  #main {
    height: 100vh;
  }
}

#row-1 {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

#col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#col-2 {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 20px 0;
}

#name {
  margin-top: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: left;
}

#portrait {
  max-width: 320px;
  max-height: 320px;
  padding: 0 20px;
}

/* 404 */

#not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  #not-found-wrapper {
    height: 85vh;
  }
}

#not-found-title {
  margin-bottom: 0;
}

#not-found-text {
  text-align: center;
}
